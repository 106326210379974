
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { defineComponent, ref, computed } from 'vue';
import {
  IonContent,
  IonPage,
  IonButton,
  IonImg,
  IonThumbnail,
  onIonViewWillEnter,
} from '@ionic/vue';
import HeaderMain from '@/components/shared/HeaderMain.vue';
import StarRating from 'vue-star-rating';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { openToast } from '@/helpers/toast';
import { Agenda, Speaker } from '@/types/interface';
import { fireBaseTime } from '@/helpers/formatDate';
import bizvento from '@/services/bizvento';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    HeaderMain,
    IonContent,
    IonPage,
    IonButton,
    IonImg,
    IonThumbnail,
    StarRating,
  },
  setup() {
    const rating = ref(0);
    const review = ref<string>('');
    const store = useStore();
    const route = useRoute();
    const id = computed(() => route.params.id);
    const isLoading = ref<boolean>(false);
    const isLoadingReview = ref<boolean>(false);
    const talks = ref([]);
    const router = useRouter();
    const fallbackImage = '../assets/fallback/speaker.png';

    const speakers = computed((): Speaker[] => {
      return store.state.speakers;
    });

    const singleAgenda = computed((): Agenda => {
      return (store.state.agenda.find(
        (agenda: Agenda) => agenda.id === id.value
      ) || {}) as Agenda;
    });

    const handleAgendaReview = async () => {
      isLoadingReview.value = true;
      try {
        await bizvento.user.createComment(
          process.env.VUE_APP_EVENT_ID,
          store.state.user.uid,
          String(singleAgenda.value.id),
          'agenda',
          review.value,
          rating.value
        );
        return openToast(
          'Review has been submitted successfully',
          2000,
          'bottom',
          'success'
        );
      } catch (error) {
        console.log(error);
      } finally {
        isLoadingReview.value = false;
      }
    };

    const isFavourite = computed(() => {
      return store.state.favouriteAgenda.find(
        (agenda: Agenda) => agenda.id === id.value
      );
    });

    const addToFavourite = async () => {
      isLoading.value = true;
      try {
        await bizvento.user.createFavourite(
          process.env.VUE_APP_EVENT_ID,
          store.state.user.uid,
          String(singleAgenda.value.id),
          'agenda'
        );
        store.commit('SET_FAVOURITE_AGENDA', singleAgenda.value);
        return openToast(
          'Agenda has been added to favourites list',
          2000,
          'bottom',
          'success'
        );
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const removeFromFavourite = async (agenda: Agenda): Promise<void> => {
      isLoading.value = true;
      try {
        await bizvento.user.deleteFavourite(
          process.env.VUE_APP_EVENT_ID,
          store.state.user.uid,
          String(agenda.id)
        );
        store.commit('REMOVE_FAVOURITE_AGENDA', agenda);
        return openToast(
          'Agenda has been removed from favourites list',
          2000,
          'bottom'
        );
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const getReviewAgendaReview = async (): Promise<any> => {
      try {
        const data = await bizvento.user.getComment(
          process.env.VUE_APP_EVENT_ID,
          store.state.user.uid,
          String(singleAgenda.value.id)
        );
        if (data) {
          rating.value = data.rating;
          review.value = data.body;
        }
      } catch (error) {
        console.log(error);
      }
    };
    const speakerByAgenda = () => {
      return speakers.value.filter((speaker: Speaker) => {
        const speakerAgenda = speaker.id;
        // @ts-ignore
        if (speakerAgenda in singleAgenda.value.speakers) {
          // @ts-ignore
          talks.value.push(speaker);
          return speaker;
        }
      });
    };

    onIonViewWillEnter(() => {
      talks.value = [];
      speakerByAgenda();
      getReviewAgendaReview();
    });

    return {
      fireBaseTime,
      addToFavourite,
      removeFromFavourite,
      handleAgendaReview,
      router,
      fallbackImage,
      isLoading,
      talks,
      isLoadingReview,
      review,
      rating,
      singleAgenda,
      isFavourite,
    };
  },
});
